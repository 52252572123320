<template>
  <ValidationContext name="booking">
    <div class="d-flex flex-column gap-4">
      <div class="row">
        <div class="col-12 d-flex flex-column gap-2">
          <div class="col-12 mb-3">
            <h4 class="mb-4">1. Hvem bestiller?</h4>
            <div class="form-group">
              <label for="name">
                Navn på bestiller <span class="text-danger">*</span>
              </label>
              <input type="text" class="form-control" v-model="name">
            </div>
          </div>
          <hr>
          <WizardSection :disabled="!name">
            <WizardSection :disabled="dateRefreshing">
              <template #overlay>
                <div class="d-flex flex-row gap-2">
                  <FontAwesomeIcon :icon="['fal', 'refresh']" size="3x" spin/>
                  <span class="fs-4 fw-bold">
                  Laster åpningstider
                </span>
                </div>
              </template>
              <h4 class="mb-4">2. Velg dag</h4>
              <div class="row">
                <div class="col-12 col-md-8">
                  <DatePicker v-model="date" v-model:opening="openingHours" v-model:fetching="dateRefreshing"/>
                </div>
                <div class="col-12 col-md-4 mt-4 mt-lg-0">
                  <WebshopInfo :opening-hours="openingHours"/>
                </div>
              </div>
            </WizardSection>
          </WizardSection>

          <WizardSection :enabled="date">
            <hr>
            <h4 class="mb-4">3. Velg produkt</h4>
            <ScenarioPicker :date="date" v-model="scenario" v-model:fetching="scenarioRefreshing"/>
          </WizardSection>

          <WizardSection :enabled="scenario">
            <hr>
            <h4>4. Velg antall spillere</h4>
            <PlayerPicker :scenario="scenario" v-model:players="players" v-model:games="games"
                          v-model:alcohol="alcohol" v-model:locked="locked"/>

          </WizardSection>

          <WizardSection :enabled="scenario && games && players" v-if="scenario?.allowNotes">
            <hr>

            <h4>5. Har du noen kommentar?</h4>
            <div class="col-12 mt-4">
              <textarea class="form-control h-auto" cols="30" rows="4" v-model="comment"></textarea>
              <slot name="below-comment"></slot>
            </div>
          </WizardSection>

          <hr>


          <SearchResults v-model="valid" :name="name" :date="date" :scenario="scenario" :players="players"
                         :games="games"
                         :alcohol="alcohol"/>
          <hr v-if="valid">

          <div class="d-flex flex-row gap-2 justify-content-center">
            <SubmitBookingButton
              :date="date"
              :name="name"
              :games="games" :players="players" :alcohol="alcohol" :valid="valid" :scenario="scenario"
              :comment="comment"/>
            <validation-message/>
          </div>
        </div>
      </div>
    </div>
  </ValidationContext>
</template>


<script setup>
import WizardSection from "../../components/WizardSection.vue";
import DatePicker from '../../components/DateTimePicker.vue'
import {onMounted, ref} from "vue";
import WebshopInfo from "./WebshopInfo.vue";
import ScenarioPicker from "./ScenarioPicker.vue";
import PlayerPicker from "./PlayerPicker.vue";
import SearchResults from "./SearchResults.vue";
import useConqueror from "../../composables/conqueror";
import useValidation from "../../composables/validation";
import ValidationContext from "../../validation/ValidationContext.vue";
import ValidationMessage from "../../validation/ValidationMessage.vue";
import SubmitBookingButton from "./SubmitBookingButton.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const date = ref(null)
const openingHours = ref(undefined)
const dateRefreshing = ref(false)

const scenario = ref(null)
const scenarioRefreshing = ref(false)

const locked = ref(false)
const games = ref(null)
const players = ref(null)
const alcohol = ref(undefined)
const valid = ref(false)
const comment = ref('')
const name = ref('')
</script>
