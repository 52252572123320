import Booking from "./Booking.vue";
import {ref} from "vue";
import Notifications from '@kyvg/vue3-notification'


export default {
  install(app, options) {
    app
      .use(Notifications)
      .provide('modal', ref(null))
      .component('v-ticket-admin-karaoke-booking', Booking)
  }
}
