<template>
  <div v-if="loading" class="d-flex flex-row align-items-center justify-content-center gap-2 p-5">
    <font-awesome-icon :icon="['fal', 'refresh']" spin size="3x"></font-awesome-icon>
    <h2>
      Sjekker tilgjengelighet
    </h2>
  </div>
  <div v-else-if="results?.available" class="bg-blue-light text-primary border-success-100 border-1 px-4 py-4 rounded-1 d-flex flex-row gap-3 align-items-center">
    <div class="">
      <font-awesome-icon :icon="['fal', 'check-circle']" size="3x" class="text-green" />
    </div>
    <div class="d-flex flex-column">
      <h2>Ledig</h2>
      <div>
      </div>
    </div>
  </div>
  <div v-else-if="results?.available === false" class="bg-red text-danger border-success-100 border-1 px-4 py-4 rounded-1 d-flex flex-row gap-3 align-items-center">
    <div class="">
      <font-awesome-icon :icon="['fal', 'times-circle']" size="3x" class="text-green" />
    </div>
    <div class="d-flex flex-column">
      <h2>Ikke ledig</h2>
      <div>
        Vi har desverre ikke ledige baner på ønsket tidspunkt.
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, ref, watch} from "vue";
import axios from "axios";
import {formatISO} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const props = defineProps({
  alcohol: Boolean,
  games: Number,
  players: Number,
  scenario: Number,
  date: Date,
  modelValue: Boolean,
  name: String
})

const emit = defineEmits(['update:modelValue'])

const available = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

const results = ref(null)
const loading = ref(false)
const error = ref(null)

watch(
  () => results.value?.available,
  (n) => available.value = n === true
)

watch(
  () => [props.scenario, props.players, props.games, props.date, props.name],
  async () => {
    if (props.scenario && props.players && props.games && props.date, props.name) {
      try {
        loading.value = true

        var response = await axios.get('/api/bowling/booking/availability', {
          params: {
            datetime: formatISO(props.date),
            players: props.players,
            games: props.games,
          }
        })
        if (response.status === 200)
          results.value = response.data
        else if (response.status === 400) {
          // TODO: Make this a better message
          results.value = null
          error.value = 'Unexpected error'
        } else
          results.value = null
        error.value = 'Unexpected error'
      } finally {
        loading.value = false
      }
    } else {
      results.value = []
    }

  })

</script>


<style scoped>

</style>
