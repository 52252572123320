<template>
  <div class="d-flex flex-column">
    <div>
      <div class="form-group">
        <label for="drinks">Alkoholservering (Ja/Nei)</label>
      </div>
      <VueNextSelect
        v-model="drinks"
        id="drinks"
        :options="['Ja', 'Nei']"
        class="form-control"
      ></VueNextSelect>
    </div>
     <div>
      <div class="form-group">
        <label for="drinks">Vil du være plassert nærme noen som drikker (Ja/Nei)</label>
      </div>
      <VueNextSelect
        v-model="nearbyDrinks"
        id="drinks"
        :options="['Nei', 'Ja', 'Bryr meg ikke']"
        class="form-control"
      ></VueNextSelect>
    </div>
  </div>
</template>

<script setup>
import VueNextSelect from "vue-next-select";
import useBooking from "../composables/booking";

import { inject } from "vue";

const { date } = useBooking();
VueNextSelect;
</script>
