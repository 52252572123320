<template>
    <div class="form-group">
        <label for="games">Antall spill</label>
        <input type="number" id="games" class="form-control" v-model="games" />
    </div>
</template>

<script setup>
import { inject, provide } from "vue";
import useBooking from "../composables/booking";

const { games } = useBooking();
</script>

<style scoped>
/* Legge til custom styling */
</style>
