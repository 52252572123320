<template>
  <ValidationContainer>
    <form action="" @submit.prevent="">
      <div class="m-0 my-4 m-sm-5 d-flex flex-column">
        <div class="bg-blue-light px-4 py-4 rounded d-flex flex-column gap-3">
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label class="label-small" for="datepicker">Velg dato</label>
                <DatePicker
                    id="datepicker" v-model="date" :disabled-dates="disabledDates"
                    :max-date="addMonths(new Date(Date.now()), 1)"
                    :min-date="new Date(Date.now())"
                    locale="nb"
                >
                  <template #default="{ inputValue, inputEvents }">
                    <input :value="inputValue" name="props.name" type="hidden"/>
                    <input :value="humanDate" class="form-control" v-on="inputEvents"/>
                  </template>
                </DatePicker>
                <ValidationMessage value="start">
                  <span v-if="resourcePrice" class="pt-2">
                    Pris er {{ resourcePrice }} per person, per time
                  </span>
                </ValidationMessage>

              </div>
            </div>
            <div class="col-12 col-md-5">
              <div class="form-group">
                <label class="label-small" for="datepicker">Hvor mange timer vil dere booke?</label>
                <select id="" v-model="hours" class="form-control select-control" name="hours">
                  <option :disabled="!bookableHours[0]" :value="1">
                    1 time
                  </option>
                  <option :disabled="!bookableHours[1]" :value="2">
                    2 timer
                  </option>
                  <option :disabled="!bookableHours[2]" :value="3">
                    3 timer
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group">
                <label class="label-small" for="datepicker">Hvor mange er dere?</label>
                <select id="" ref="paxInput" v-model="pax" class="form-control form-select" name="">
                  <option v-for="k in Array(maxParticipants).keys()" :key="k" :value="k+1">{{ k + 1 }}</option>
                </select>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="my-5 mx-sm-5 d-flex flex-column-reverse flex-md-row gap-4">
        <div class="col-12 col-md-8">
          <div class="">
            <LoadingOverlay key="top" state="karaokeSubmit">
              <template #loading> &nbsp;</template>
              <LoadingOverlay key="fclo" :override="!hasData" state="karaoke">
                <div class="d-grid gap-2" style="grid-template-columns: 1fr 1fr 1fr 1fr;">
                  <button v-for="(date) in intervals"
                          :class="{'btn-primary': (date.isAvailable && periodIsAvailable(date.date, hours)) && !currentlySelected(date), 'btn-secondary':  currentlySelected(date),'btn-default': !(date.isAvailable && periodIsAvailable(date.date, hours))}"
                          :disabled="!(date.isAvailable && periodIsAvailable(date.date, hours))"
                          class="btn btn-sm"
                          href=""
                          @click.prevent="selectSlot(date)"
                  >
                    {{ date.time }}
                  </button>
                </div>
              </LoadingOverlay>
            </LoadingOverlay>
          </div>
        </div>

        <div class="col-12 col-md-4 d-flex flex-column pe-4 top-0">
          <div class="Webshop__title">
            Din bestilling
          </div>

          <div class="d-flex flex-column gap-2 sticky-top">
            <div class="d-flex flex-column gap-4 pt-3">
              <LoadingOverlay state="karaokeSubmit">
                <template #loading>&nbsp;</template>
                <div class="bg-blue-light px-4 py-4 rounded d-flex flex-column gap-3">
                  <div class="d-flex flex-ro gap-2" v-if="price">
                    <div class="col-2">{{ pax }}x</div>
                    <div class="col-7">
                      personer
                      <div>
                        <strong>
                          <small>
                            {{ price * hours }},- per person
                          </small>
                        </strong>
                      </div>
                    </div>
                    <div class="col-3">{{ price * pax * hours }},-</div>
                  </div>
                </div>
              </LoadingOverlay>
              <div v-if="selectionError" class="alert alert-danger m-0">
                <slot :error="selectionError" name="selection">
                  {{ selectionError }}
                </slot>
              </div>
              <slot name="tos"/>
              <SubmitButton :disabled="selectionError || !resourceId || !date || !selectedEntity"
                            :payload="visualPayload"/>
            </div>
          </div>
        </div>

      </div>
    </form>
  </ValidationContainer>
</template>

<script setup>
import {computed, ref, watch} from 'vue'
import LoadingOverlay from "../../components/LoadingOverlay.vue";
import {addMonths, format, setHours, setMinutes, startOfToday} from 'date-fns'
import {DatePicker} from "v-calendar";
import useBookingDates from "./composables/booking-dates";
import useBookingAvailability from "./composables/booking-availability";
import useSelection from "./composables/booking-selection";
import ValidationContainer from "../../components/errors/ValidationContainer.vue";
import SubmitButton from "./SubmitButton.vue";
import ValidationMessage from "../../components/errors/ValidationMessage.vue";


const props = defineProps({
  date: {
    type: String,
    default: () => format(startOfToday(), "yyyy-MM-dd"),
  },
  payload: {
    type: Object,
    required: true,
  },
  maxParticipants: {
    type: Number,
    default: 12
  },
  disabledDates: {
    type: Array,
    default: () => ([])
  },
  pax: Number,
  start: String,
  hours: Number
})


/// The date selected by the user
const {date, end, humanDate} = useBookingDates(props.date, 2)

const selectionError = ref(null)

/// The hours variable holds how many hours has been selected by the user
const hours = ref(1)

/// The selected timeslot,
const selectedEntity = ref(null)


/// if date or number of hours is changed, the slot is nulled in order to not allow
/// users to book invalid time slots
const nullSelectedEntity = () => selectedEntity.value = null;
watch(hours, nullSelectedEntity)
watch(date, nullSelectedEntity)

const {
  start,
  bookableHours,
  humanTime,
} = useSelection(() => false, 15)

const disabledDates = ref([
  '2023-12-24',
  '2023-12-25',
  '2023-12-31',
  '2024-01-01'
]);

const {
  isLoading,
  hasData,
  resources,
  events,
  intervals,
  price,
  periodIsAvailable,
} = useBookingAvailability(date, hours, 'karaoke')


const resourceId = computed(() => selectedEntity.value?.room.id ?? null)
const selectSlot = (slot) => selectedEntity.value = slot
const currentlySelected = (data) => selectedEntity.value?.time === data?.time

const pax = ref(6)
const paxInput = ref(null)
watch(humanTime, () => paxInput.value.focus())
// Clear selected time slot, if date is changed
watch(isLoading, (n) => {
  if (n) {
    selectedEntity.value = null
  }
})

const visualPayload = computed(() => ({
  start: selectedEntity.value?.time
      ? format(setMinutes(setHours(date.value, selectedEntity.value.time.slice(0, 2)), selectedEntity.value.time.slice(-2)), "yyyy-MM-dd HH:mm")
      : null,
  hours: hours.value,
  resourceId: resourceId.value,
  visitorCount: pax.value,
}))

</script>
