<script setup>
import useLoadingState from "../composables/loadingState";
import {ref} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const props = defineProps({
  state: String,
  override: Boolean,
})

const loading = props.state ? useLoadingState(props.state) : ref(null)
</script>

<template>
<teleport to="#loading" v-if="override || loading">
  <div class="px-4 py-2 rounded bg-black">
    <font-awesome-icon :icon="['fal', 'spinner']" spin></font-awesome-icon>
    <span class="fw-bold">
      Laster data
    </span>
  </div>
</teleport>
</template>

<style scoped>

</style>
