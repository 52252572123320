import axios from "axios";
import {addDays, format, formatISO, isBefore, parseISO} from "date-fns";


export default function useConqueror() {
  const getOpeningHoursForDay = async (date) => {
    let data = (await axios.get(`/api/bowling/booking/opening-hours/${format(date, 'yyyy-MM-dd')}`))?.data

    if(!data?.start || !data?.end) {
      return null
    }

    data.start = parseISO(data.start)
    data.end = parseISO(data.end)

    if (isBefore(data.end, data.start))
      data.end = addDays(data.end, 1)

    return data
  }

  const getScenariosForDay = async (date) => {
    return (await axios.get(`/api/bowling/booking/scenarios`, {
      params: {
        date: formatISO(date),
      }
    }))?.data
  }

  const createTemporaryBooking = async (date, scenario, players, games, comment, name) => {
    let response = await axios.post(`/api/bowling/booking/temporary-booking`, {
      scenario_id: scenario.id,
      games,
      players,
      comment,
      name,
      datetime: formatISO(date)
    })
    if(response.status != 200) {
      throw response.data
    }
    return response.data
  }


  return {
    getOpeningHoursForDay,
    getScenariosForDay,
    createTemporaryBooking
  }
}
