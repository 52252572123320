<template>
  <slot />
</template>

<script setup>
import {reactive, provide, ref} from 'vue'
provide('errorMessage', ref(null))
provide('validations', reactive({}))
</script>

<style scoped>

</style>
