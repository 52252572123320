import {computed, inject} from "vue";

export default function useValidationErrors(key) {
  const errors  = inject('validations')
  const message = computed(() => (errors[key] ?? [])[0] ?? null)
  const hasError = computed(() => Object.keys(errors ?? {}).includes(key))

  return {
    message,
    hasError
  }
}
