<template>
  <WizardSection :enabled="true">
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="form-group">
          <label for="datepicker">{{ title }}</label>
          <DatePicker
            v-model="selectedDate" id="datepicker" locale="nb" @update:pages="updateDate"
            :min-date="new Date(Date.now())"
            :disabled-dates="disabledDates"
          >
            <template #default="{ inputValue, inputEvents }">
              <input type="hidden" :value="inputValue" name="props.name"/>
              <input :value="visibleDateTime" v-on="inputEvents" class="form-control"/>
            </template>
          </DatePicker>
        </div>
        <div>
          <ValidationFieldMessage field="datetime"/>
        </div>

      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="">Klokkeslett</label>
          <select v-model="selectedDateTime" name="" id="" class="form-control form-select"
                  :disabled="fetchingOpeningHours || openingSlots.length === 0"
                  :class="{'disabled': fetchingOpeningHours || openingSlots.length === 0}">
            <option :value="null" v-if="openingSlots.length == 0"> ---</option>
            <option :value="thedate" v-for="thedate in openingSlots">
              {{ formatInTimeZone(thedate, 'Europe/Oslo', 'HH:mm') }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </WizardSection>
</template>

<script setup>
import {DatePicker} from 'v-calendar';
import 'v-calendar/style.css';
import {computed, onMounted, ref, watch} from "vue";
import {addMinutes, eachMinuteOfInterval, format, isAfter, parseISO, startOfDay, startOfToday, subHours} from 'date-fns'
import {nb} from 'date-fns/locale'
import {formatInTimeZone} from "date-fns-tz";
import WizardSection from "./WizardSection.vue";
import useConqueror from "../composables/conqueror";
import ValidationFieldMessage from "../validation/ValidationFieldMessage.vue";

const props = defineProps({
  name: String,
  modelValue: Date,
  opening: Object,
  fetching: {type: Boolean, default: false},
  title: {type: String, default: 'Velg Dato'},
  format: {type: String, default: () => "iiii d LLL yyyy"},
  locale: {type: String, default: () => document.documentElement.lang || "nb"}
});

const disabledDates = ref(['2022-01-01']);


const emit = defineEmits(['update:modelValue', 'update:openingHours', 'update:fetching'])

const {getOpeningHoursForDay} = useConqueror()

const selectedDate = ref(null)

const selectedDateTime = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

watch(selectedDateTime, (n) => n ? selectedDate.value = n : null)

const openingHours = computed({
  get: () => props.opening,
  set: (value) => emit('update:opening', value)
})

const openingSlots = computed(() => {
  if (openingHours.value == null || openingHours.value?.start == null || openingHours.value?.end == null) {
    return []
  }

  debugger

  /// Remove one hour from the times in order to not be able to book one hour before closing
  let time = {start: openingHours.value.start, end: subHours(openingHours.value.end, 1)}

  const now = addMinutes(new Date(Date.now()), 60)
  try {
    return eachMinuteOfInterval(time, {step: 15})
      .filter(date => isAfter(date, now))
  } catch (e) {
    console.error(e)
    return [];
  }
})

const fetchingOpeningHours = computed({
  get: () => props.fetching || false,
  set: (value) => emit('update:fetching', value)
})

const visibleDateTime = computed(() => {
  if (selectedDate.value) {
    return formatInTimeZone(selectedDate.value, 'Europe/Oslo', props.format, {locale: nb})
  }
  return ""
})

let lastValue = null
watch(selectedDate, async (n, o) => {
  console.log("selectedDate", selectedDate.value, n, o)
  if (n && format(n, 'yyyy-MM-dd') !== lastValue) {

    fetchingOpeningHours.value = true
    try {
      console.log("Getting data")
      openingHours.value = await getOpeningHoursForDay(n)
      lastValue = format(n, 'yyyy-MM-dd')
    } finally {
      fetchingOpeningHours.value = false
    }
  }
})

onMounted(() => {
  if (props.modelValue) {
    selectedDateTime.value = typeof props.value == 'string'
      ? parseISO(props.value)
      : props.value

    selectedDate.value = startOfDay(selectedDateTime.value)
  } else {
    selectedDate.value = startOfToday()
  }

})


</script>
