<template>
  <div class="m-4">
    <div>
      <slot name="date"></slot>
    </div>
    <div :class="{ 'opacity-25': !date, 'pointer-events-none': !date }">
      <slot name="players"></slot>
    </div>
    <div
      :class="{ 'opacity-25': !date, 'pointer-events-none': !players }"
      :readonly="!players"
    >
      <slot name="games"></slot>
    </div>
    <div :class="{ 'opacity-25': !date, 'pointer-events-none': !date }">
      <slot name="drinks"></slot>
    </div>
    <div :class="{ 'opacity-25': !date, 'pointer-events-none': !date }">
      <!--<slot name="scenario" :date="date"></slot>-->
    </div>
    <div :class="{ 'opacity-25': !date, 'pointer-events-none': !date }">
      <slot name="time" :date="date"></slot>
    </div>
    <div>
      <slot name="options" v-if="validPayload"></slot>
    </div>
  </div>
</template>

<script setup>
import {provide, ref} from "vue";
import useBooking from "../composables/booking";

const {date, players} = useBooking();
const showScenario = () => date.value != null;

const xhr = new XMLHttpRequest();
const url = "/api/bowling/booking/opening-hours";
let params = `from=${date}&to=${date}`;

xhr.open("GET", url + "?" + params, true);
xhr.onreadystatechange = function() {
  if (xhr.readyState === 4 && xhr.status === 200) {
    var response = JSON.parse(xhr.responseText);
    // Håndtere response nedenfor
    console.log(response);
  }
};

xhr.send();

</script>

<style>
.opacity-25 {
  opacity: 0.25;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2s;
}

.pointer-events-none {
  pointer-events: none;
}
</style>
