import {addMonths, format, formatISO, parse, startOfToday} from "date-fns";
import {computed, ref} from "vue";
import locale from "date-fns/locale/nb";

export default function useBookingDates(initialDate, bookingIntervalInMonths = 2) {
  const today = startOfToday()
  const start = today
  const end = addMonths(today, bookingIntervalInMonths)
  console.log(initialDate)
  const date = ref(initialDate ? parse(initialDate, 'yyyy-LL-dd', new Date) : today)

  const humanDate = computed(
    () => date.value ? format(date.value, "d. MMMM Y", {locale}) : ""
  )

  const formDate = computed(
    () => date.value ? formatISO(date.value) : null
  )

  return {
    date,
    today,
    start,
    end,
    humanDate,
    formDate
  }
}
