<template>
  <slot></slot>
</template>

<script setup>
import useValidation from "../composables/validation";
import {provide} from "vue";

const props = defineProps(['name'])
provide('error-context', props.name)

</script>

<style scoped>

</style>
