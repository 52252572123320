import {inject, watch} from 'vue';
import {addMonths, format, setDay} from "date-fns";
import axios from "axios";

export default function useBooking() {
  const date = inject('date');
  const selectedMonth = inject('selectedMonth');

  const dateRefreshing = inject('dateRefreshing')
  const validDates = inject('validDates')
  const selectedDayOpeningHours = inject('selectedDayOpeningHours')

  const players = inject('players');
  const games = inject('games');
  const time = inject('time');
  const drinks = inject("drinks");
  return {
    date,
    selectedMonth,
    selectedDayOpeningHours,
    validDates,
    dateRefreshing,
    players,
    games,
    time,
    drinks
  }
}
