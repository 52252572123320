import {inject} from "vue";

export default function useModal(state, text, content) {
  console.log(state)
  return new Promise((resolve) => state.value = {
      resolve: function (value) {
        resolve(value)
        state.value = null
      },
      text: text,
      content: content,
    }
  )
}
