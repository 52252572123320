<template>
    <div class="form-group">
        <label for="players">Antall spillere</label>
        <input type="number" id="players" class="form-control" v-model="players" />
    </div>
</template>

<script setup>
import { inject } from "vue";
import useBooking from "../composables/booking";

const { players } = useBooking();
</script>

<style scoped>
/* Legge til custom styling */
</style>
