<template>
  <slot name="default" :message="message">
    <span>{{ message }}</span>
  </slot>
</template>

<script setup>
import {inject} from "vue";
import useValidation from "../composables/validation";

const props = defineProps({
  name: {
    type: String,
    required: false
  },
  field: {
    type: String,
    required: true,
  }
})

const { getError, validators, state } = useValidation(props.name ?? inject('error-context'))
const message = getError(props.field)


</script>

<style scoped>

</style>
