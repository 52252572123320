<template>
  <div class="row">
    <div class="col-12 col-md-8">
      <div class="row">

        <div class="col-12 col-md-4">
          <div class="form-group">
            <label for="">
              Antall serier
            </label>
            <select v-model="games" class="form-control form-select" :disabled="locked">
              <option v-for="price in (scenario?.pricekeys ?? [])" :key="price.quantity" :value="price.quantity">
                {{ price.description }} (kr. {{ price.value }},-)
              </option>
            </select>
            <ValidationFieldMessage field="games"/>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="form-group">
            <label for="">
              Antall spillere
            </label>
            <input type="number" class="form-control" :min="scenario?.minPlayers ?? 1" :max="scenario?.maxPlayers ?? 10"
                   v-model="players" :disabled="locked">
            <ValidationFieldMessage field="players"/>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="form-group" v-if="price">
            <label for="">
              Totalt
            </label>
            <div class="fs-4">
              <input type="text" class="form-control border-0 bg-white" disabled :value="price">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4 d-flex">
      <div class="form-group d-flex flex-column gap-1 justify-content-end align-items-start">
        <label for="">&nbsp;</label>
      </div>
    </div>
  </div>

</template>

<script setup>

import {computed, watch, ref} from "vue";
import ValidationFieldMessage from "../../validation/ValidationFieldMessage.vue";

const props = defineProps({
  scenario: Object,
  players: Number,
  games: Number,
  alcohol: Boolean,
  locked: Boolean
})

const intPlayers = ref(props.players ?? 1)
const intGames = ref(props.games ?? "1")
const intAlcohol = ref(props.alcohol ?? undefined)

const emit = defineEmits(['update:players', 'update:games', 'update:alcohol', 'update:locked'])

const players = computed({
  get: () => props.players,
  set: (value) => emit('update:players', value)
})

const games = computed({
  get: () => props.games,
  set: (value) => emit('update:games', value)
})

const alcohol = computed({
  get: () => props.alcohol,
  set: (value) => emit('update:alcohol', value)
})

const price = computed(() => {
  if (!props.scenario || !games.value) {
    return null
  }
  const value = (props.scenario?.pricekeys.filter(({quantity}) => quantity == games.value)?.[0]?.value ?? 0)

  if (value) {
    return `kr. ${value * players.value},-`
  }
  return null
})


const locked = computed({
  get: () => props.locked,
  set: (value) => emit('update:locked', value)
});

const pluralize = (value, singular, plural) => value === 1 ? singular : plural

const submit = () => {
  players.value = intPlayers.value
  games.value = intGames.value
  alcohol.value = intAlcohol.value
  locked.value = true
}

const reset = () => {
  players.value = null
  games.value = null
  alcohol.value = undefined
  locked.value = false
}

watch(() => props.scenario?.minPlayers || props.scenario?.maxPlayers, () => {
  if (props.scenario?.minPlayers && props.scenario?.maxPlayers && players.value != 0) {
    players.value = Math.max(props.scenario.minPlayers, Math.min(props.scenario.maxPlayers, players.value || 0))
  }
})

watch(() => locked.value?.toString() + props.players?.toString() + props.games?.toString() + props.alcohol?.toString(), () => {
  if (props.players != null)
    intPlayers.value = props.players

  if (props.games != null)
    intGames.value = props.games

  if (props.alcohol !== undefined)
    intAlcohol.value = props.alcohol
})

</script>


<style scoped>

</style>
