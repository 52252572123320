<script setup>
import {inject, ref} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {useNotification} from "@kyvg/vue3-notification";


const Echo = inject('echo')

var visible = ref(false)

const { notify }  = useNotification()
Echo
  .channel('public')
  .listen('karaoke-booking-confirmed', e => {
    notify(`En ny booking er gjennomført ${e.payload.from_formatted}`)
    visible.value = true
  })

const refresh = () => {
  location.reload()
}
</script>

<template>
  <teleport to="#refresh">
    <button v-if="visible" class="btn btn-secondary btn-sm bg-black text-white px-2 py-2 rounded-2 fw-bold" @click="refresh">
      <font-awesome-icon :icon="['fal', 'refresh']"></font-awesome-icon>
      Ny booking er kommet
    </button>
  </teleport>
</template>

<style scoped>

</style>
