<script setup>

import {DatePicker} from "v-calendar";
import {computed, onMounted, ref, watch} from "vue";
import {format, parseISO} from "date-fns";
import {formatInTimeZone} from "date-fns-tz";
import {nb} from "date-fns/locale";

const props = defineProps({
  value: {
    type: [Date, String],
    default: () => null,
  },
  name: String,
  disabledDates: Array,
})

const emit = defineEmits('update:value')


const selectedDate = ref();

onMounted(() => {

  selectedDate.value = typeof props.value == 'string' ? parseISO(props.value) : props.value
})

watch(selectedDate, (value) => emit('update:value', value))

const rules = ref({
  minutes: [0, 15, 30, 45]
})

const inputValue2 = computed(() => selectedDate.value ? formatInTimeZone(selectedDate.value, 'Europe/Oslo', 'yyyy-MM-dd') : null)

const visibleDateTime = computed(() => {
  if (selectedDate.value) {
    return formatInTimeZone(selectedDate.value, 'Europe/Oslo', 'dd. MMM yyyy', {locale: nb})
  }
  return ""
})

</script>

<template>
  <DatePicker
    v-model="selectedDate" id="datepicker" locale="nb"
    :min-date="new Date(Date.now())"
    :rules="rules"
  >
    <template #default="{ inputValue, inputEvents }">
      <input type="hidden" :value="inputValue2" :name="name"/>
      <input :value="visibleDateTime" v-on="inputEvents" class="form-control"/>
    </template>
  </DatePicker>
</template>

<style scoped>

</style>
