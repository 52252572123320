<script setup>
import useValidationErrors from "../../composables/errors/error-consumer";

const props = defineProps({value: String, both: {type: Boolean, default: true}})

const {message, hasError} = useValidationErrors(props.value)
</script>

<template>
  <div class="text-danger" v-if="hasError">
    {{ message }}
  </div>
  <slot v-if="both || !hasError"></slot>
</template>

<style scoped>

</style>
