<script setup>

import nbLocale from "@fullcalendar/core/locales/nb";
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interact from "@fullcalendar/interaction";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import {computed, inject, ref, watch} from "vue";
import FullCalendar from '@fullcalendar/vue3'
import useLoadingState from "../composables/loadingState";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import Modal from "./Modal.vue";
import useModal from "../composables/useModal";
import {addDays, addHours, differenceInMinutes, format, formatISO, isSameDay, parseISO} from "date-fns";
import Duration from "../components/Duration.vue";
import axios from "axios";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import {formatInTimeZone} from "date-fns-tz";
import RefreshIndicator from "./RefreshIndicator.vue";
import onWakeUp from "@/composables/sleep";
import { DatePicker } from "v-calendar";

const props = defineProps({
  date: {
    type: Date
  }
})

onWakeUp(() => location.reload())

const loading = useLoadingState('booking')

const state = inject('modal')
const changeState = ref()
const askForInsurance = () => useModal(state, "Er du sikker at du vil oppdatere bookingen?", "are you ok?")
watch(state, (x) => {
  if (!x) {
    changeState.value = null
  }
})

const changeDate = (event) => location.href = "/admin/" + format(event, "yyyy-MM-dd")
const fcPayload = computed(() => ({
  initialDate: props.date ? formatInTimeZone(props.date, 'Europe/Oslo', 'yyyy-MM-dd') : null,
  locale: nbLocale,
  themeSystem: 'bootstrap5',
  plugins: [resourceTimelinePlugin, interact, momentTimezonePlugin],
  initialView: 'resourceTimeline',
  refetchResourcesOnNavigate: true,
  loading: function (currentlyLoading) {
    loading.value = currentlyLoading
  },

  resources: '/admin/bookings/resources',
  events: '/admin/bookings/events',
  slotMinTime: '10:00',
  slotDuration: "00:15:00",
  schedulerLicenseKey: '0036558489-fcs-1698247735',
  resourceGroupField: 'typeName',
  selectable: true,
  slotLabelFormat: {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  },
  datesSet: function (event) {
    let isToday = isSameDay(Date.now(), event.start)
    window.history.pushState({}, "", `/admin${isToday ? '' : '/' + formatInTimeZone(event.start, 'Europe/Oslo', 'yyyy-MM-dd')}`)
  },
  dateClick: function (event) {
    console.log(event)
    const date = format(event.date, 'yyyy-MM-dd')
    const from = format(event.date, 'HH:mm')
    const to = format(addHours(event.date, 1), 'HH:mm')
    const resource = event.resource?.id
    window.location.assign(`/admin/bookings/karaoke/create?date=${date}&from=${from}&to=${to}&resource=${resource}`)
  },
  select: function (event) {
    const date = format(event.start, 'yyyy-MM-dd')
    const from = format(event.start, 'HH:mm')
    const to = format(event.end, 'HH:mm')
    const resource = event.resource?.id
    window.location.assign(`/admin/bookings/karaoke/create?date=${date}&from=${from}&to=${to}&resource=${resource}`)
  },
  eventClick: function (e) {
    const url = e.event?.extendedProps?.editUrl
    if (url) {
      location.assign(url)
    }
  },
  eventChange: async function (changes) {
    console.log(changes)
    changeState.value = changes

    if (!await askForInsurance()) {
      changes.revert()
    } else {
      try {
        loading.value = true
        await axios.put(changes.event.extendedProps.editUrl, {
          from: formatISO(changes.event.start),
          to: formatISO(changes.event.end),
          resourceId: changes.event.getResources()[0].id,
        })
      } catch (e) {
        console.error(e)
        changes.revert()
      } finally {
        loading.value = false
      }
    }

  }
}))

</script>

<template>
  <div class="position-relative">
    <div class="m-6">
    <notifications position="bottom right" />
    </div>
    <LoadingOverlay state="loading"></LoadingOverlay>
    <RefreshIndicator/>
    <Teleport to="#refresh">
      <div class="relative">
      <DatePicker mode="date" @update:modelValue="changeDate" >
        <template #default="{ inputValue, inputEvents }">
          <button class="btn btn-sm bg-gray text-white" v-on="inputEvents">Velg Dato</button>
        </template>
      </DatePicker>
      </div>
    </Teleport>
    <FullCalendar key="fc" :options="fcPayload">
      <template v-slot:event-did-mount="args">
        i am begin bad
        {{ args }}
      </template>
      <template v-slot:eventContent="args">
        <div class="d-flex flex-row gap-2">
          <span class="mb-1" style="letter-spacing: 0.5px;">{{ args.event.title }}</span>
        </div>
        <div class="d-flex flex-row gap-2">
          <div>👤: {{ args.event.extendedProps?.participants }}</div>
        </div>
        <div>
          <span v-if="args.event.extendedProps.paid">💵 Har betalt</span>
          <span v-else>❌ Har ikke betalt</span>
        </div>
      </template>
    </FullCalendar>
    <LoadingIndicator state="booking"/>
  </div>
  <Modal>
    <div class="bg-light-blue bg-blue-light mb-2 px-2 py-1 text-center d-flex flex-column gap-2 py-2 rounded"
         v-if="changeState">
      <div class="d-flex flex-row gap-2 px-4 align-items-center">
        <div class="col-2 fw-bold text-uppercase" style="font-size: 0.75rem;">Felt</div>
        <div class="col-5 fw-bold text-uppercase" style="font-size: 0.75rem;">Gammel verdi</div>
        <div class="col-5 fw-bold text-uppercase" style="font-size: 0.75rem;">Ny verdi</div>
      </div>
      <div class="d-flex flex-row gap-2 px-4 align-items-center"
           :class="{'opacity-50': changeState.oldEvent.start == changeState.event.start}">
        <div class="col-2">Fra:</div>
        <div class="col-5 bg-white rounded py-1">{{ format(changeState.oldEvent.start, 'dd. MMM HH:mm') }}</div>
        <div class="col-5 bg-white rounded py-1">{{ format(changeState.event.start, 'dd. MMM HH:mm') }}</div>
      </div>
      <div class="d-flex flex-row gap-2 px-4 align-items-center"
           :class="{'opacity-50': changeState.oldEvent.end == changeState.event.end}">
        <div class="col-2">Til:</div>
        <div class="col-5 bg-white rounded py-1">{{ format(changeState.oldEvent.end, 'dd. MMM HH:mm') }}</div>
        <div class="col-5 bg-white rounded py-1">{{ format(changeState.event.end, 'dd. MMM HH:mm') }}</div>
      </div>
      <div class="d-flex flex-row gap-2 px-4 align-items-center"
           :class="{'opacity-50': differenceInMinutes(changeState.oldEvent.start, changeState.oldEvent.end) === differenceInMinutes(changeState.event.start, changeState.event.end)}">
        <div class="col-2">Varighet:</div>
        <div class="col-5 bg-white rounded py-1">
          <Duration :from="changeState.oldEvent.start" :to="changeState.oldEvent.end"/>
        </div>
        <div class="col-5 bg-white rounded py-1">
          <Duration :from="changeState.event.start" :to="changeState.event.end"></Duration>
        </div>
      </div>
      <div class="d-flex flex-row gap-2 px-4 align-items-center"
           :class="{'opacity-50': changeState.oldEvent.getResources()[0]?.title == changeState.event.getResources()[0]?.title}">
        <div class="col-2">Rom:</div>
        <div class="col-5 bg-white rounded py-1">{{ changeState.oldEvent.getResources()[0]?.title ?? '??' }}</div>
        <div class="col-5 bg-white rounded py-1">{{ changeState.event.getResources()[0]?.title ?? '??' }}</div>
      </div>
    </div>
  </Modal>
</template>

<style scoped>

</style>
