<template>
  <WizardSection :disabled="dateRefreshing">
    <template #overlay>
      <div class="d-flex flex-column gap-4">
        Laster åpningstider
        <font-awesome-icon :icon="['fal', 'spinner']" spin size="2x"></font-awesome-icon>
      </div>
    </template>
    <div v-if="openingHours === undefined"></div>
    <div v-else-if="openingHours?.enabled" class="info-box text-primary bg-teal-light px-2 py-4 rounded-1">
      <span class="info-box__title">{{ openingHours.message }}</span>
      <div class="info-box__opening-hours d-flex flex-row gap-2">
        <span aria-label="Bowlingen åpner">
          {{ opens }}
        </span>
        <span> - </span>
        <span aria-label="Bowlingen stenger">
          {{ closes }}
        </span>
      </div>
    </div>
    <div v-else class="info-box text-text-danger bg-danger px-2 py-4 rounded-1">
      {{ openingHours.message }}
    </div>
  </WizardSection>

</template>
<script setup>
import useBooking from "../../composables/booking";
import WizardSection from "../../components/WizardSection.vue";
import {computed} from "vue";
import {formatInTimeZone} from 'date-fns-tz'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

let props = defineProps({
  openingHours: Object,
})

const dateParser = (key) => () => {
  if (!props.openingHours?.[key]) {
    return;
  }
  const date = props.openingHours?.[key]
  return formatInTimeZone(date, 'Europe/Oslo', 'HH:mm');
}

const opens = computed(dateParser('start'))
const closes = computed(dateParser('end'))

</script>

<style scoped lang="scss">

.info-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-weight: 200;
    font-size: 0.85rem;
    text-transform: uppercase;
  }

  &__opening-hours {
    font-size: 1.5rem;
  }
}

</style>
