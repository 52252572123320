import {Channel, Connector} from "laravel-echo";
import MessageChannel from "@apility/message-channel";
import {inject} from "vue";

function ConnectorFactory(alias, options = {}) {
  return NetflexConnector
}

class NetflexChannel extends Channel {
  messageChannel = null;

  events = {}

  constructor(channel) {
    super()
    this.events = {}
    this.messageChannel = channel
    this.messageChannel.on('message', function (e) {
      const event = e.data?.event
      if (event) {
        (this.events[event] ?? []).forEach(cb => cb(Object.assign({}, {event_id: e.id}, e.data)))
      }

    }.bind(this))
  }

  /**
   * Listen for an event on the channel instance.
   */
  listen(event, callback) {
    console.log(this.events)
    if (!this.events) {
      this.events = {}
    }

    if (this.events[event] === undefined) {
      this.events[event] = []
    }

    this.events[event].push(callback)

    console.log(this.events)
    console.log("add listener", this, this.events)
    return this
  }

  /**
   * Stop listening to an event on the channel instance.
   */
  stopListening(event) {

    this.events[event] = []

    return this
  }


  /**
   * Register a callback to be called anytime a subscription succeeds.
   */
  subscribed(callback) {
    return this
  }

  /**
   * Register a callback to be called anytime an error occurs.
   */
  error(callback) {
    return this
  }

}

class NetflexConnector extends Connector {
  messageChannels = {}

  connect() {
    this.messageChannels = {}
  }

  /**
   * Get a channel instance by name.
   */
  channel(channel) {
    if (this.messageChannels[channel] === undefined) {
      const echoChan = new NetflexChannel(new MessageChannel(this.options.channel ?? "test", channel ?? "test2", Object.assign({}, this.options ?? {}, {
        pingInterval: 30,
        host: 'broadcast.netflexapp.com',
        prefixChannel: true,
        secure: true
      })))
      echoChan.options = this.options
      this.messageChannels[channel] = echoChan

    }

    return this.messageChannels[channel]
  }

  /**
   * Get a private channel instance by name.
   */
  privateChannel(channel) {
    throw "Not implemented"
  }

  presenceChannel(channel) {
    throw "not implemented"
  }

  leave(channel) {
    const chan = this.messageChannels[channel]

    if (chan) {
      chan.socket.close()
      this.messageChannels[channel] = undefined
    }

  }

  /**
   * Leave the given channel.
   */
  leaveChannel(channel) {
    this.leave(channel)
  }

  /**
   * Get the socket_id of the connection.
   */
  socketId() {
    return "not-implemented"
  }

  /**
   * Disconnect from the Echo server.
   */
  disconnect() {
    Object.keys(this.messageChannels).forEach(name => this.leave(name))
  }

}

/**
 *
 * @returns {NetflexConnector}
 */
export function useEcho() {
  return inject('echo')
}

export default {
  NetflexConnector,
  ConnectorFactory,
  useEcho
}
