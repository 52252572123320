
import {
  faSpinner,
  faCheck,
  faCheckCircle,
  faRefresh,
  faTimesCircle,
  faTrash,
  faUser, faBowlingBallPin, faGameBoard, faTimes
} from "@fortawesome/pro-light-svg-icons";

import {library, dom} from "@fortawesome/fontawesome-svg-core";

library.add(faSpinner, faCheck, faCheckCircle, faRefresh, faTimesCircle, faTrash, faUser, faBowlingBallPin, faGameBoard, faTimes)
dom.watch()
