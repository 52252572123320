<script setup>
import {inject} from "vue";
const state = inject('modal')

</script>
<template>
  <teleport to="body" v-if="state">
    <dialog open>
      <h6>{{ state?.text }}</h6>
      <slot>
        <p v-html="state?.content ?? ''"></p>
      </slot>
      <form method="dialog" class="d-flex flex-row gap-2">
        <button class="btn btn-sm btn-primary" autofocus @click.prevent="state?.resolve(true)">Ja</button>
        <button class="btn btn-sm btn-primary" @click.prevent="state?.resolve(false)">Nei</button>
      </form>
    </dialog>
  </teleport>
</template>
<style>
dialog {
  position: absolute;
  top: 25vh;
  z-index: 1000;
  width: 50vw;

  border: 1px var(--bs-primary) solid;
  border-bottom-width: 4px;
}

::backdrop {
  background-image: linear-gradient(
    45deg,
    magenta,
    rebeccapurple,
    dodgerblue,
    green
  );
  opacity: 0.75;
}

</style>
