import {computed, ref, onMounted} from 'vue'
import {addHours, addMinutes, differenceInHours, format, formatISO} from "date-fns";

export default function useSelection(overlaps, buffer = 15, initialStart = null, initialHours = null, initialResourceId = null) {

    const start = ref(initialStart && initialHours ? parseISO(initialStart) : null)
    const end = ref(initialStart && initialHours ? addHours(parseISO(initialStart), initialHours) : null)
    const resourceId = ref(initialResourceId)

    onMounted(() => {
        if(start.value && end.value) {
            if(overlaps({start: start.value, end: end.value, resourceId: resourceId.value})) {
                start.value = null
                end.value = null
                resourceId.value = null
            }
        }
    })

    const hours = computed({
        get: () => {
            console.log(start.value, end.value)
            return start.value && end.value ? differenceInHours(end.value, start.value) : 1;
        },
        set: (value) => {
            if (start.value) {
                end.value = addHours(start.value, value)
            }
        }
    })

    const bookableHours = computed(() => {
        return [1, 2, 3].map((hours) => start.value == null || !overlaps({
            start: start.value,
            end: addMinutes(addHours(start.value, hours), 0),
            resourceId: resourceId.value
        }))
    })

    const setSelection = (_start, _end, _resourceId) => {

        let errorReason = "overlaps"
        if (differenceInHours(_end, _start) < 1) {
            errorReason = "less-than-hour"
        }

        let hours = Math.max(1, Math.min(3, Math.abs(differenceInHours(_end, _start, { roundingMethod: 'ceil' }))))

        _end = addHours(_start, hours)
        let doesOverlapWithExisting = overlaps({
            start: _start,
            end: addHours(_start, hours),
            resourceId: _resourceId,
        })

        while (doesOverlapWithExisting && hours > 0) {
            hours -= 1
            _end = addHours(_start, hours)
            doesOverlapWithExisting = overlaps({
                start: _start,
                end: addHours(_start, hours),
                resourceId: _resourceId,
            })
        }

        if (doesOverlapWithExisting) {
            clearSelection()
            throw errorReason
        } else {
            start.value = _start
            end.value = _end
            resourceId.value = _resourceId
        }
        return doesOverlapWithExisting
    }

    const selectionComputed = computed(() => {
        if (!start.value) {
            return []
        }
        return [
            {
                id: 'nowbg',
                resourceId: resourceId.value,
                start: start.value,
                end: addMinutes(end.value, buffer),
                display: 'background',
                selectable: false,
                editable: false
            },
            {
                id: 'now',
                resourceId: resourceId.value,
                start: start.value,
                end: addMinutes(end.value, 0),
                title: `${format(start.value, "HH:mm")} - ${format(end.value, "HH:mm")}`,
                selectable: false,
                editable: false
            }
        ]
    })


    const clearSelection = () => {
        start.value = null
        end.value = null
        resourceId.value = null
    }

    const formDate = computed(
        () => start.value ? format(start.value, 'yyyy-MM-dd HH:mm') : null
    )

    const hasSelection = computed(() => start.value && end.value && resourceId.value)
    const humanTime = computed(() => start.value ? format(start.value, "HH:mm") : null)
    const humanEndTime = computed(() => end.value ? format(end.value, "HH:mm") : null)
    return {
        hasSelection,
        start,
        end,
        resourceId,
        hours,
        bookableHours,
        setSelection,
        clearSelection,
        selectionComputed,
        formDate,
        humanTime,
        humanEndTime,
    }
}
