<template>
  <WizardSection :disabled="loadingScenarios" v-if="date">
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="d-flex flex-row gap-2" v-if="scenarios.length || fetching">
          <button @click="setValue(scenario)" :class="[{'btn-primary': value?.id === scenario.id, 'btn-secondary': value?.id !== scenario.id}, 'btn']" v-for="scenario in scenarios">{{ scenario.name }}</button>
          <ValidationFieldMessage field="scenario" />
        </div>
        <div class="d-flex flex-row gap-2" v-else>
          <div class="alert alert-warning">
            Det er ikke mulig å booke noen produkter til denne tiden
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mt-4 mt-lg-0">
        <div class="d-flex flex-row gap-4 align-items-center bg-blue-light px-4 py-4 rounded-1 text-primary" v-if="fetching">
          <div>
            <font-awesome-icon :icon="['fal', 'refresh']" size="4x" spin />
          </div>
          <div class="flex-grow-1 flex-shrink-0">
            <div class="ScenarioPicker__selected-name">Laster produkter</div>
          </div>
        </div>
        <div class="d-flex flex-row gap-4 align-items-center bg-blue-light px-4 py-4 rounded-1 text-primary" v-else-if="value">
          <div>
            <font-awesome-icon :icon="['fal', 'check-circle']" size="4x" />
          </div>
          <div class="flex-grow-1 flex-shrink-0">
            <div class="ScenarioPicker__selected-text">Du har valgt</div>
            <div class="ScenarioPicker__selected-name">{{ value.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </WizardSection>
  <template v-else>
    <slot>
      Velg både tid og dato før du går videre
    </slot>
  </template>
</template>

<script setup>

import WizardSection from "../../components/WizardSection.vue";
import {computed, ref, watch} from "vue";
import useConqueror from "../../composables/conqueror";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import ValidationFieldMessage from "../../validation/ValidationFieldMessage.vue";

const props = defineProps({
  date: Date,
  fetching: {
    type: Boolean,
    default: false
  },
  modelValue: Object
})

const emit = defineEmits(['update:modelValue', 'update:fetching'])

const scenarios = ref([])
const {getScenariosForDay} = useConqueror()

const fetching = computed({
  get: () => props.fetching,
  set: (value) => emit('update:fetching', value)
})

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

const setValue = (newValue) => value.value = newValue

watch(() => props.date, async newDate => {
  console.log("props.date", newDate)
  if(!newDate) {
    return
  }
  try {
    fetching.value = true
    scenarios.value = []
    value.value = null
    scenarios.value = await getScenariosForDay(props.date)
    if(scenarios.value.length === 1) {
      value.value = scenarios.value[0]
    }
  } finally {
    fetching.value = false
  }
})

</script>


<style scoped>
.ScenarioPicker__selected-text {
  text-transform: uppercase;
  font-weight: 100;
}
.ScenarioPicker__selected-name {
  font-size: 1.25rem;
}
</style>
