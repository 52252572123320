import {inject} from "vue";

export default function useErrorProvider() {
  var message = inject('errorMessage')
  var state = inject('validations')


  const clearValidationErrors = () => Object.keys(state).forEach(key => state[key] = undefined)
  const setValidationErrors = (errors) => Object.keys(errors).forEach(key => state[key] = errors[key])

  return {
    message,
    state,
    setValidationErrors,
    clearValidationErrors,
  }
}
