<script setup>
import {computed} from "vue";
import {differenceInHours, differenceInMinutes} from "date-fns";

const props = defineProps({
  from: Date,
  to: Date
})

const hours = computed(() => {
  return differenceInHours(props.to, props.from, {roundingMethod: 'floor'})
})

const minutes = computed(() => {
  return differenceInMinutes(props.to, props.from) - (60 * hours.value)
})

</script>

<template>
<span>
  <span aria-label="hours">{{ hours }}t</span>
  <span aria-label="minutes" v-if="minutes">&nbsp;{{ minutes }}m </span>
</span>
</template>

<style scoped>

</style>
