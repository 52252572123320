<template>
  <div v-if="override || loading" class="position-relative">
    <slot></slot>
    <div
        class="position-absolute overlay inset-0 d-flex flex-column justify-content-center align-items-center bg-light gap-2">
      <slot name="loading">
        <font-awesome-icon :icon="['fal', 'refresh']" size="4x" spin/>
        Laster informasjon
      </slot>
    </div>
  </div>
  <slot v-else/>
</template>

<script setup>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import useLoadingState from "../composables/loadingState";

const props = defineProps({
  state: String,
  override: Boolean
})

const loading = useLoadingState(props.state)

</script>

<style scoped>
.inset-0 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay {
  background: rgba(255, 255, 255, 0.75);
  z-index: 10000;
}
</style>
