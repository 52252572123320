<template>
  <button
    :disabled="!valid || booking"
    class="btn btn-primary d-flex flex-row gap-2 justify-content-center align-items-center"
    @click.prevent="book"
  >
    <FontAwesomeIcon :icon="['fal', 'refresh']" spin v-if="booking" />
    <slot name="invalid" v-if="!valid">
      Fyll ut skjemaet for å fortsette
    </slot>
    <slot name="booking" v-else-if="booking">
      Gjennomfører booking
    </slot>
    <slot v-else>
      Book nå!
    </slot>
  </button>
</template>

<script setup>

import {computed, ref} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import useValidation from "../../composables/validation";
import useConqueror from "../../composables/conqueror";

const props = defineProps(['date','games', 'players', 'alcohol', 'valid', 'scenario', 'errors', 'comment','name'])
const emit = defineEmits(['update:errors'])

const booking = ref(false)

const errors = useValidation('booking')

const text = computed(() => {
  if(!valid) {

  }
})

const {createTemporaryBooking} = useConqueror()
const book = async () => {
  if (!props.valid ||booking.value) {
    return
  }

  try {
    booking.value = true
    errors.state.value = null
    let response = await createTemporaryBooking(props.date, props.scenario, props.players, props.games, props.comment, props.name)
    location.href = response.url
  } catch (e) {
    errors.state.value = e
    console.error(e)
    booking.value = false
  }
}
</script>

