<template>
  <div>
    <BookingWizard>
      <template #date>
        <div>
          <DatePicker></DatePicker>
        </div>
      </template>
      <template #players>
        <PlayersPicker></PlayersPicker>
      </template>
      <template #games>
         <GamesPicker></GamesPicker>
      </template>
      <template #drinks>
        <AlcoholPicker></AlcoholPicker>
      </template>
      <template #scenario="{ date }">
        Valgt dato er {{ date }}
        <input type="number" />
      </template>
      <template #time>
        <TimePicker></TimePicker>
      </template>
    </BookingWizard>
  </div>
</template>

<script setup>
import { provide, ref } from "vue";
import BookingWizard from "./BookingWizard.vue";
import DatePicker from "./DateTimePicker.vue";
import PlayersPicker from "./PlayersPicker.vue";
import GamesPicker from "./GamesPicker.vue";
import AlcoholPicker from "./AlcoholPicker.vue";

const date = ref(null);
const players = ref(null);

provide("date", date);
provide("players", players);




const address = ref(null);

const step = ref(1);

const setStep = (newValue) => (step.value = newValue);

const nextStep = () => (step.value = step.value + 1);
const previousStep = () => (step.value = step.value - 1);

const email = ref(null);
const naaaame = ref(null);
</script>

<style scoped>
/* Legge til custom styling */
/*
* Dato 
* Antall spillere, Antall spill, Alkoholservering(y/n), 
* klokkeslett
* Velg 
*/
</style>
