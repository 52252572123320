import "../sass/app.scss";
import 'bootstrap'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'vue-next-select/dist/index.css';
import './fontawesome'

import { reactive } from "vue";
import NetflexEcho from './echo'

// Vue
import {createApp} from 'vue';
import DateTimePicker from './components/DateTimePicker';
import BookingForm from './components/BookingForm';


import {Calendar, DatePicker} from 'v-calendar';
import 'v-calendar/style.css';
import WebshopForm from "./bowling/booking/WebshopForm.vue";
import KaraokeBookingForm from "./karaoke/booking/KaraokeBookingForm.vue";
import TicketAdmin from "./ticketadmin";
import GenericDatePicker from "./components/GenericDatePicker.vue";
import Echo from "laravel-echo";

createApp({
  components: {
    BookingForm,
    Calendar,
    DatePicker,
    'v-karaoke-booking-form': KaraokeBookingForm,
    'v-bowling-booking-form': WebshopForm,
    'v-date-picker': GenericDatePicker,
  }
})
  .provide('overlays', reactive({}))
  .use(TicketAdmin)
  .provide('echo', new Echo({
    broadcaster: NetflexEcho.NetflexConnector,
    channel: 'eef097cc-6bad-351f-3e98-fab578d94555',
  }))
  .mount('#app');
