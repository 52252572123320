import {onMounted} from "vue";

export default function onWakeUp(callback, interval = 5000, mustSkip = 10) {

  onMounted(() => {
    let timer = Date.now()
    let interval = 5000
    setInterval(() => {
      const start = Date.now()
      let diff = Math.abs(timer - start)
      timer = start
      if (diff > (interval * mustSkip)) {
        callback()
      }
    }, interval)
  })


}
