<template>
  <div class="btn btn-primary" :class="{'disabled': disabled || isLoading, 'opacity-25': disabled}" @click="submit">
    <template v-if="isLoading">
      Booker
    </template>
    <template v-else>
      Book
    </template>
  </div>
  <div class="text-danger">
    {{ message ?? '' }}
  </div>
</template>

<script setup>
import useLoadingState from "../../composables/loadingState";
import useErrorProvider from "../../composables/errors/error-provider";
import axios from "axios";

const props = defineProps({
  disabled: Boolean,
  state: {
    type: String,
    default: 'karaokeSubmit'
  },
  payload: Object
})

const {errors, message, setValidationErrors, clearValidationErrors} = useErrorProvider()
const isLoading = useLoadingState(props.state)

const submit = async () => {
  try {
    isLoading.value = true
    message.value = null
    clearValidationErrors()

    let response = await axios.post(location.pathname, Object.assign(props.payload, {
      '_token': document.querySelector('meta[name=csrf]').content
    }), {
      headers: {
        'Accept-Language': 'nb',
      }
    })

    location.assign(response.data.url)

  } catch (e) {
    if(e.response?.data?.message) {
      message.value = e.response.data.message || 'En ukjent feil har oppstått'
      setValidationErrors(e.response?.data?.errors ?? {})

    } else {
      message.value = 'En ukjent feil har oppstått!'
      setValidationErrors({})
    }

  } finally {
    isLoading.value = false
  }


}
</script>
