import {computed, reactive} from "vue";

const validators = reactive({})

export default function useValidation(key) {

  const state = computed({
    get: () => validators[key],
    set: (value) => validators[key] = value
  })

  const getError = (validationField) => computed(() => (validators[key] ?? {}).errors?.[validationField]?.[0])
  const message = computed(() => {
    if(validators[key]) {
      return validators[key].message
    }
    return null
  })
  return {
    state,
    message,
    getError,
    validators
  }

}
