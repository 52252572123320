<template>
  <slot name="default" :message="message" v-if="message">
    <span>{{ message }}</span>
  </slot>
</template>

<script setup>

import {inject} from "vue";
import useValidation from "../composables/validation";

const props = defineProps(['name'])
const  { message } = useValidation(props.name ?? inject('error-context'))
</script>


<style scoped>

</style>
