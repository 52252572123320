
import {computed, inject} from 'vue'

/**
 *
 * @param name String
 * @returns {WritableComputedRef<boolean>}
 */
export default function useLoadingState(name) {

  var loadingStates = inject('overlays')

  if(!loadingStates[name]) {
    loadingStates[name] = false
  }

  return computed({
    get: () => loadingStates[name],
    set: v => loadingStates[name] = v
  })
}
