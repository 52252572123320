import axios from "axios";

import {
  addHours,
  addMinutes,
  eachMinuteOfInterval,
  format,
  isBefore,
  isSameMinute,
  parseISO,
  subHours
} from "date-fns";

import useLoadingState from "../../../composables/loadingState";
import {computed, onMounted, ref, watch} from "vue";
import {formatInTimeZone} from "date-fns-tz";


/**
 *
 * @param date
 * @param bookedHours Ref<int>
 * @param loadingKey
 * @returns {{hasData: Ref<boolean>, isLoading: WritableComputedRef<boolean>, slotMaxTime: Ref<string>, resources: Ref<*[]>, events: Ref<*[]>, slotMinTime: Ref<string>}}
 */
export default function useBookingAvailability(date, bookedHours, loadingKey = 'karaoke') {

    const isLoading = useLoadingState(loadingKey)


    const price = ref(null)
    const open = ref(null)
    const events = ref([])
    const resources = ref([])
    const slotMinTime = ref(null)
    const slotMaxTime = ref(null)

    let lastAttempt = null
    let hasData = ref(false)
    let availabilityError = ref(null)

    onMounted(() => getDataForDay(date.value))
    watch(date, (newDate) => getDataForDay(newDate))

    const getDataForDay = async (date) => {
        if (date === lastAttempt || !date) {
            return
        }
        lastAttempt = date
        isLoading.value = true

        try {
            const response = await axios.get(`/api/karaoke/booking/availability2/${format(date, 'Y-M-d')}`)
            console.log(response.data)
            price.value = response.data.price
            open.value = response.data.open
            events.value = response.data.rooms
            slotMinTime.value = response.data.opens ? parseISO(response.data.opens) : null
            slotMaxTime.value = response.data.closes ? parseISO(response.data.closes) : null
            hasData.value = true
        } catch (e) {
            availabilityError.value = e
            hasData.value = false
        } finally {
            isLoading.value = false
        }
    }

    const intervals = computed(() => {
        if (!slotMinTime.value || !slotMaxTime.value) {
            return [];
        }

        console.log("BO,", bookedHours.value, date, slotMaxTime.value)
        return eachMinuteOfInterval({
            start: slotMinTime.value,
            end: slotMaxTime.value
        }, {step: 15}).map(date => {
                let visual = formatInTimeZone(date, 'Europe/Oslo', 'HH:mm')
                let validRoom = getFirstRoom(date)
          let cutoffDate = subHours(slotMaxTime.value, bookedHours.value);
          return {
                    time: visual,
                    date: date,
                    isAvailable:  validRoom != null && (isBefore(date, cutoffDate) || isSameMinute(date, cutoffDate) ),
                    room: validRoom
                }
            }
        )
    })

    const roomCheckPredicate = (time, grace) => (room, date) => {
        let visual = formatInTimeZone(date, 'Europe/Oslo', 'HH:mm')
        return (!time || !room.slots.includes(visual)) && (!grace || !room.grace_periods.includes(visual))
    }
    const getFirstRoom = (date) => {
        const rooms = [...events.value ?? []]
        rooms.sort(() => Math.random() > .5 ? 1 : -1);

        for (let room in rooms) {
            room = rooms[room]
            if (roomCheckPredicate(true, true)(room, date)) {
                return room
            }
        }

        return null;
    }

    const isAvailable = (date, grace = true) => {
        return events.value.some(room => {
            return roomCheckPredicate(true, grace)(room, date)
        })
    }

    const periodIsAvailable = (date, hours) => {
        let period = eachMinuteOfInterval({start: addHours(date, 0), end: addMinutes(addHours(date, hours), 15)}, {step: 15})
        return period.every(date => isAvailable(date, false))
    }

    return {
        open,
        periodIsAvailable,
        isAvailable,
        availabilityError,
        hasData,
        isLoading,
        events,
        intervals,
        resources,
        price,
        slotMinTime,
        slotMaxTime,
    }

}
